import React from "react"
import { Link,graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSmall from "../components/headerSmall"
import ComparisonTable from "../components/comparisonTable"
import InsuranceDetailsSection from "../components/insuranceDetailsSection"

export const query = graphql`
    query ($provider: String,$product: String){
      site {
        siteMetadata {
        title,
        year,
        guideyear
        }
      },
      husforsikring2020Csv(Provider: {eq: $provider}, Product: {eq: $product}){
        Provider
        Product
        Defaqto_Stjerne
      },
      table: allHusforsikring2020Csv(sort: {fields: Defaqto_Stjerne, order: DESC}) {
        edges {
          node{
            Provider
            Product
            Defaqto_Stjerne
          }
        }
      }
    }
  `

export default({data}) => {
  const year = data.site.siteMetadata.year
  const type = "husforsikring"
  const path = "hus"
  const aspects = "40"

  const provider = data.husforsikring2020Csv.Provider
  const providerUrl = "" // Not used atm - but should be implemented
  const product = data.husforsikring2020Csv.Product
  const rating = data.husforsikring2020Csv.Defaqto_Stjerne

  return(
    <Layout>
      <InsuranceDetailsSection
        table={data.table}
        year={year}
        type={type}
        path={path}
        aspects={aspects}
        provider={provider}
        providerUrl={providerUrl}
        rating={rating}
        product={product}
      />
    </Layout>
  )
}